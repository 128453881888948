
import { reactive, onMounted, computed, ref } from 'vue';
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { Actions } from "@/store/enums/LotEnums.ts";
import { Actions as ReportActions } from "@/store/enums/ReportsEnums.ts";
import { useStore } from 'vuex';
export default {
  name: 'LotShippingHistoryByLotNumber',
  setup(){

    const store = useStore();

    const lot_id = ref(null);

    const submitButton = ref<HTMLElement | null>(null);

    const errors = reactive({
      value : {}
    });

    const runReport = (e) => {

      if(submitButton.value){
        submitButton.value.setAttribute("data-kt-indicator", "on");
      }

      store.dispatch(ReportActions.LOT_SHIPPING_HISTORY,{
          searchBy : {
          type : 'lotId',
          value : lot_id.value
        }
      }).then((response) => {

        errors.value = [];
        submitButton.value?.removeAttribute("data-kt-indicator");

        const blob = new Blob([response], { type: 'application/pdf', filename : 'labRequestForm.pdf' } as object)

        const url = window.URL.createObjectURL(blob)

        window.open(url)

      }).catch((response) => {

        errors.value = true;

        submitButton.value?.removeAttribute("data-kt-indicator")

      })
    }

    onMounted(() => {

      store.dispatch(Actions.GET_ALL_COMPOSITE_LOTS);

      setCurrentPageBreadcrumbs("Reports",[
        {title : "Lot Shipping History by Lot Number",to : "/reports/lot-shipping-history-by-lot-number"}
      ]);
    });

    return {
      store,
      lot_id,
      runReport,
      submitButton,
      errors
    }
  }
}
